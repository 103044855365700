import React from "react"
import Seo from "../components/molecules/Seo"

const About = () => (
  <>
    <Seo title="About" />
    <p>Paragraph text. This site has an about page.</p>
  </>
)

export default About
